iframe {
    pointer-events: none;
}

#twitter_feed iframe {
    pointer-events: all;
}

.apexcharts-legend {
    padding-right: 50px;
}

.MuiTablePagination-displayedRows {
    display: none;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

